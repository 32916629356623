<template>
  <div class="wrap">
    <div class="footer-nav">
      <ul>
        <li>Shanghai Xin Can Electric Equipment Co.,Ltd</li>
        <li>
          Room 2004, Lane 7, 803 Shuangcheng Road, Baoshan District, Shanghai,
          China.
        </li>
        <li>Post Code 201901</li>
        <li>USCI: 91310116594751847J</li>
        <li class="beian"><a href="https://beian.miit.gov.cn/#/Integrated/index">沪ICP备2025113770号-1</a>&nbsp;|&nbsp;<img src="@/assets/img/police.png" width="14"/> <a href="https://beian.mps.gov.cn/#/query/webSearch?code=31011302008242" rel="noreferrer" target="_blank">沪公网安备31011302008242号</a></li>
        <!-- <li class="beian">
          <img src="@/assets/img/police.png" width="14"/> <a href="https://beian.mps.gov.cn/#/query/webSearch?code=31011302008242" rel="noreferrer" target="_blank">沪公网安备31011302008242号</a>
        </li> -->
      </ul>
      <a
        href="https://map.baidu.com/search/%E4%B8%AD%E5%9B%BD%E4%B8%8A%E6%B5%B7%E5%B8%82%E5%AE%9D%E5%B1%B1%E5%8C%BA%E5%8F%8C%E5%9F%8E%E8%B7%AF803%E5%8F%B711%E5%BC%841201%E5%AE%A4./@13524483.125,3661723,14.82z?querytype=s&da_src=shareurl&wd=%E4%B8%AD%E5%9B%BD%E4%B8%8A%E6%B5%B7%E5%B8%82%E5%AE%9D%E5%B1%B1%E5%8C%BA%E5%8F%8C%E5%9F%8E%E8%B7%AF803%E5%8F%B711%E5%BC%841201%E5%AE%A4%E3%80%82&c=289&src=0&pn=0&sug=0&l=12&b=(13482307,3610922;13564291,3671210)&from=webmap&biz_forward=%7B%22scaler%22:2,%22styles%22:%22pl%22%7D&device_ratio=2"
        class="map"
      >
        <img src="@/assets/img/map1.jpg" />
      </a>
      <!-- <ul class="r-block">
        <li><span>Tel: </span><span>+86-21-6071-7083</span></li>
        <li><span>Fax: </span><span>+86-21-6071-7084</span></li>
        <li>
          <span>Email: </span
          ><a href="mailto:yangai@xincanhk.com">yangai@xincanhk.com</a>
        </li>
        <li>
          <span></span
          ><a href="greatocean@xincanhk.com">greatocean@xincanhk.com</a>
        </li>
      </ul> -->
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  // 定义属性
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.footer-nav {
  background-color: rgba(35, 35, 35, 1);
  padding: 3%;
  display: flex;
  color: #fff;
  justify-content: space-around;
  align-items: center;
  ul {
    width: 30%;
    li {
      line-height: 38px;
    }
  }
  .map {
    display: block;
    border: 1px solid #fff;
    width: 700px;
    height: 250px;
    img {
      display: block;
      margin: 10px auto;
      width: 680px;
      height: 230px;
    }
  }
  .r-block li {
    display: flex;
    span:first-child {
      width: 80px;
    }
    a {
      color: #fff;
    }
  }
}
.beian {
  color: #d5d5d5;
  font-size: 14px;
  margin-top: 10px;
  a {
    color: #d5d5d5;  // 新增链接颜色设置
    text-decoration: none;
  }
}
</style>
